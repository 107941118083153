<template>
  <div class="examine-container">
    <a-modal
      :visible="visibleModal"
      class="modal-style"
      @cancel="cancel"
      :maskClosable="false"
      :width="1139"
    >
      <template slot="footer">
        <a-button @click="cancel">返回</a-button>
        <a-button v-if="config.type === '审核'" @click="handleReturn('退回')"
          >退回</a-button
        >
        <a-button
          v-if="config.type === '审核'"
          type="primary"
          @click="handlePass('通过')"
          >通过</a-button
        >
      </template>
      <div>{{ config.type }}</div>
      <a-divider />
      <div style="padding-right: 8rem">
        <div class="mb1">审核意见：</div>
        <a-textarea
          style="width: 100%"
          palceholder="请输入内容"
          :disabled="config.type === '查看'"
          :maxLength="128"
          v-model="tradeReviewDescription"
        >
        </a-textarea>
        <a-divider />

        <div class="mb2">
          <span class="bord"></span>
          <span class="title">企业基础信息</span>
        </div>
        <div class="enterprise-container">
          <div class="layout-inline mt2 mb1">
            <FormInput :config="inputObj.tradeCompany" />
            <FormInput :config="inputObj.tradeCreditCode" />
          </div>
          <div class="layout-inline mb1">
            <FormInput :config="inputObj.tradeAddress" />
            <FormInput :config="inputObj.tradeSale" />
          </div>
          <div class="layout-inline mb1">
            <FormInput :config="inputObj.tradeBusinessScope" />
            <FormInput :config="inputObj.tradeType" />
          </div>
          <div class="layout-inline mb1">
            <FormInput :config="inputObj.tradeTelephone" />
            <FormInput :config="inputObj.tradeWebsiteAddress" />
          </div>
          <div>
            <label class="label star">公司营业执照：</label>
            <div style="display: inline-block">
              <viewer :images="tradeLicense">
                <img
                  v-for="(item, i) in tradeLicense"
                  :key="i"
                  class="imgStyle"
                  :src="item"
                />
              </viewer>
            </div>
          </div>

          <div>
            <div class="star">产品主要出口销售地:</div>
            <a-checkbox-group
              style="width: 80%"
              disabled
              v-model="selectedSalesArea"
              :options="allSalesArea"
            />
          </div>
        </div>

        <div class="mb2 mt2">
          <span class="bord"></span>
          <span class="title">个人信息</span>
        </div>
        <div class="layout-inline mt2 mb1">
          <FormInput :config="inputObj.tradeName" />
          <FormInput :config="inputObj.tradePosition" />
        </div>
        <div class="layout-inline mt2 mb1">
          <FormInput :config="inputObj.tradePhone" />
          <FormInput :config="inputObj.tradeEmail" />
        </div>
        <div>
          <label class="label">名片：</label>
          <div style="display: inline-block">
            <viewer :images="tradeCallingCard">
              <img
                v-for="(item, i) in tradeCallingCard"
                class="imgStyle"
                :key="i"
                :src="item"
              />
            </viewer>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import FormInput from "@/components/formInput";
import { API } from "@/apix/merchantQuery";
import { tips } from "@/utils/tips";

export default {
  mixins: [mixins],
  props: {
    visibleModal: {
      default: false,
      type: Boolean,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FormInput,
  },
  data() {
    return {
      selectedSalesArea: ["港澳台", "北美"], // 选中项
      allSalesArea: [], // 渲染数组
      tradeReviewDescription: null, // 审核意见
      tradeCallingCard: [], // 名片数组
      tradeLicense: [], // 营业执照
      checkResult: null, // 审核结果
      // 企业信息
      inputObj: {
        tradeCompany: {
          title: "企业名称",
          content: "",
          starVisible: true,
        },
        tradeCreditCode: {
          title: "统一社会信用代码",
          content: "",
          starVisible: true,
        },
        tradeAddress: {
          title: "企业地址",
          content: "",
          starVisible: true,
        },
        tradeSale: {
          title: "公司上年度销售额",
          content: "",
        },
        tradeBusinessScope: {
          title: "企业主营范围",
          content: "",
          starVisible: true,
        },
        tradeType: {
          title: "企业类型",
          content: "",
          starVisible: true,
        },
        tradeTelephone: {
          title: "公司电话",
          content: "",
        },
        tradeWebsiteAddress: {
          title: "企业官网链接",
          content: "",
        },
        tradeName: {
          title: "姓名",
          content: "",
          starVisible: true,
        },
        tradePosition: {
          title: "职位",
          content: "",
        },
        tradePhone: {
          title: "手机号",
          content: "",
          starVisible: true,
        },
        tradeEmail: {
          title: "邮箱",
          content: "",
          starVisible: true,
        },
      },
    };
  },
  methods: {
    // 得到详情数据
    getDetailData(id) {
      API.detailData(id).then((res) => {
        this.backFillData(res.data);
      });
    },
    // 回填数据
    backFillData(data) {
      for (const key in this.inputObj) {
        this.inputObj[key].content = data[key];
      }
      if (data.tradeReviewDescription) {
        this.tradeReviewDescription = data.tradeReviewDescription;
      }
      this.tradeCallingCard = data.tradeCallingCard;
      this.tradeLicense = data.tradeLicense;
      this.allSalesArea = data.allSalesArea.map((item) => {
        return item.dictValue;
      });
      this.selectedSalesArea = data.selectedSalesArea;
    },
    setPreview() {
      //给预览图设置参数
      this.tradeCallingCard.forEach((img) => {
        img.src = img;
        img.w = 200; //这是大图的宽
        img.h = 200;
      });
      return this.tradeCallingCard;
    },
    // 处理退回
    handleReturn(type) {
      let self = this;
      this.$confirm({
        title: "是否确定退回该商户?",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.checkResult = false;
          self.handleSubmit(type);
        },
      });
    },
    // 处理通过
    handlePass(type) {
      let self = this;
      this.$confirm({
        title: "是否确定通过该商户?",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.checkResult = true;
          self.handleSubmit(type);
        },
      });
    },
    // 提交
    handleSubmit(type) {
      if (this.tradeReviewDescription === null && type === "退回") {
        this.$message.error("请输入审核意见");
        return;
      } else {
        let checkDTO = {
          checkResult: this.checkResult,
          tradeId: this.config.id,
          tradeReviewDescription: this.tradeReviewDescription,
        };
        API.examineMerchant(checkDTO).then((res) => {
          tips(res, "处理");
          if (res.success) {
            this.cancel();
            this.$parent.getSourceData("退回");
          }
        });
      }
    },
  },
  created() {
    this.getDetailData(this.config.id);
  },
};
</script>

<style lang="scss" scoped>
.mt2 {
  margin-top: 2rem;
}
.mb1 {
  margin-bottom: 1rem;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: rgb(129, 128, 128);
}
.bord {
  background: #ff4600;
  width: 1rem;
  border: 4px solid #ff4600;
  margin-right: 5px;
}
.layout-inline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.wrap {
    flex-wrap: wrap;
  }
}
.imgStyle {
  width: 10rem;
  margin-right: 1rem;
  border: 1px solid #eee;
}
.label {
  width: 18.5rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: top;
}

// 预览图片
.preview figure {
  width: 10rem;
}

.preview figure img {
  width: 100%;
}

/deep/ .ant-checkbox-group-item {
  width: 10rem;
  margin-bottom: 1rem;
}

.ant-input {
  width: 28.5rem;
}
</style>
