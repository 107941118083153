<template>
  <div>
    <div class="search-box top-container">
      <div class="lable-box search-item">
        <label class="label">用户名:</label>
        <a-input
          placeholder="请输入"
          v-model.trim="queryFrom.tradeUsername"
          @keyup.enter="queryBtn"
        ></a-input>
      </div>
      <div class="lable-box search-item">
        <label class="label">手机号:</label>
        <a-input
          placeholder="请输入"
          v-model.trim="queryFrom.tradePhone"
        ></a-input>
      </div>
      <div class="lable-box search-item">
        <label class="label">企业名称:</label>
        <a-input
          placeholder="请输入"
          v-model.trim="queryFrom.tradeCompany"
        ></a-input>
      </div>
      <div class="lable-box search-item">
        <label class="label">联系人:</label>
        <a-input
          placeholder="请输入"
          v-model.trim="queryFrom.tradeName"
        ></a-input>
      </div>
      <div class="lable-box search-item">
        <label class="label">出口销售地:</label>
        <a-select
          show-search
          mode="multiple"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
          :default-active-first-option="false"
          :not-found-content="null"
          :filterOption="filterOpt"
          v-model.trim="queryFrom.salesArea"
        >
          <a-select-option
            v-for="(item, i) in salesAreaList"
            :value="item.dictCode"
            :key="i"
          >
            {{ item.dictValue }}
          </a-select-option>
        </a-select>
      </div>
      <div class="lable-box search-item">
        <label class="label">认证状态:</label>
        <a-select
          placeholder="请选择"
          :filterOption="filterOpt"
          v-model.trim="queryFrom.tradeState"
        >
          <a-select-option
            v-for="(item, i) in tradeStateList"
            :value="item"
            :key="i"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="lable-box search-item" >
        <label class="label">服务:</label>
        <a-select
          v-model="queryFrom.serviceType"
          placeholder="请选择"
        >
          <a-select-option v-for="item in serviceOption" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-item right-btn">
        <a-button @click="handleReset" class="button-item"> 重置 </a-button>
        <a-button @click="search" class="button-item"> 查询 </a-button>
      </div>
    </div>

    <div class="bottom-container">
      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :loading="loading"
        :scroll="{ y: 500 }"
        :pagination="pagination"
      >
        <template slot="serviceType" slot-scope="text, record">
          <div>{{ serviceEnm[record.serviceType] }}</div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <span
            v-show="record.tradeState === '待审核'"
            :style="{ color: `${getColor('主题')}` }"
            class="hover"
            @click="openModal('审核', record.tradeId)"
            >审核</span
          >
          <span
            v-show="
              record.tradeState === '已认证' || record.tradeState === '未认证'
            "
            :style="{ color: `${getColor('主题')}` }"
            class="hover"
            @click="openModal('查看', record.tradeId)"
            >查看</span
          >
          <span
            :style="{ color: `${getColor('主题')}` }"
            class="hover"
            @click="openUpgradeModal(record)"
            >服务升级</span
          >
        </template>
        <template slot="tradeState" slot-scope="text, record">
          <span
            v-show="record.tradeState === '未认证'"
            :style="{ color: `${getColor(record.tradeState)}` }"
            >未认证</span
          >
          <span
            v-show="record.tradeState === '待审核'"
            :style="{ color: `${getColor(record.tradeState)}` }"
            >待审核</span
          >
          <span
            v-show="record.tradeState === '已认证'"
            :style="{ color: `${getColor(record.tradeState)}` }"
            >已认证</span
          >
        </template>
      </a-table>
    </div>

    <!-- 审核 -->
    <Examine
      :visibleModal="visibleModal"
      v-if="visibleModal"
      :config="configFrom"
    />
    <!-- 服务升级 -->
    <serviceUpgrade
      v-if="upgradeModal.visible"
      :upgradeModal="upgradeModal"
      :config="upgradeConfig"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "用户名",
    ellipsis: true,
    dataIndex: "tradeUsername",
  },
  {
    title: "手机号",
    ellipsis: true,
    dataIndex: "tradePhone",
  },
  {
    title: "企业名称",
    ellipsis: true,
    dataIndex: "tradeCompany",
  },
  {
    title: "联系人",
    ellipsis: true,
    dataIndex: "tradeName",
  },
  {
    title: "注册时间",
    ellipsis: true,
    dataIndex: "createTime",
  },
  {
    title: "出口销售地",
    ellipsis: true,
    dataIndex: "salesArea",
  },
  {
    title: "认证状态",
    ellipsis: true,
    dataIndex: "tradeState",
    scopedSlots: { customRender: "tradeState" },
  },
  {
    title: "服务",
    ellipsis: true,
    dataIndex: "serviceType",
    scopedSlots: { customRender: "serviceType" },
  },
  {
    title: "服务情况",
    ellipsis: true,
    dataIndex: "serviceInfo",
    scopedSlots: { customRender: "serviceInfo" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { filterOpt } from "@/utils/utils";
import { getColor } from "../../utils/color";
import { API } from "@/apix/merchantQuery";
import Examine from "./examine.vue";
import serviceUpgrade from "./serviceUpgrade.vue";

export default {
  mixins: [pagination, mixins],
  components: {
    Examine,
    serviceUpgrade,
  },
  data() {
    return {
      columns,
      data: [],
      loading: false,
      tradeStateList: ["未认证", "待审核", "已认证"], // 认证状态数据
      serviceOption: [
      { value: 0, label: "无" },
        { value: 1, label: "贸易端" },
        { value: 2, label: "游客端" },
        { value: 3, label: "体验" },
      ],
      serviceEnm: { 0: "无", 1: "贸易端", 2: "游客端", 3: "体验" },
      salesAreaList: [], // 出口销售地
      queryFrom: {
        tradeName: null, // 用户名
        tradePhone: null, // 手机号
        tradeCompany: null, // 企业名称
        tradeUsername: null, // 联系人
        salesArea: undefined, // 出口销售地
        serviceType: undefined, //服务
        tradeState: "待审核", // 认证状态
      },
      configFrom: {
        id: null,
        type: null,
      },
      upgradeConfig: {},
      visibleModal: false, // 弹框显示
      upgradeModal: {
        visible: false,
        type: null,
      },
    };
  },
  methods: {
    filterOpt,
    getColor,
    openModal(type, id) {
      this.configFrom.id = id;
      this.configFrom.type = type;
      this.visibleModal = true;
    },
    closeModal() {
      this.visibleModal = false;
    },
    openUpgradeModal(record) {
      this.upgradeModal.visible = true;
      this.upgradeConfig = record;
    },
    getSourceData(type) {
      if (type === "退回" || type === "通过") {
        this.pagination.current = 1;
      }
      let area = this.queryFrom.salesArea
        ? this.queryFrom.salesArea.toString()
        : undefined;
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
        salesArea: area,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },
    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.tradeState = undefined;
      this.queryFrom.salesArea = undefined;
      this.queryFrom.serviceType = undefined;
      this.getSourceData();
    },
    search(){
      this.pagination.current = 1;
      this.getSourceData()
    },
    // 获取出口销售地下拉
    getSaleArea() {
      API.saleArea().then((res) => {
        this.salesAreaList = res.data;
      });
    },
  },
  created() {
    this.getSourceData();
    this.getSaleArea();
  },
};
</script>

<style lang="scss" scoped>
.hover {
  cursor: pointer;
  margin-right: 10px;
}
.search-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .search-item {
    width: 26%;
    text-align: right;
    .button-item {
      margin-left: 10px;
      &:nth-child(2) {
        background-color: #ff4600;
        color: #fff;
      }
    }
  }
  .right-btn {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .ant-input {
    width: 100%;
  }
}
.ant-table-wrapper {
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
.label {
  width: 100px;
  line-height: 34px;
  text-align: right;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
