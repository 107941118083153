<template>
  <a-modal
    title="服务升级"
    :visible="upgradeModal.visible"
    @cancel="handleCancel"
    width="900px"
    @ok="handleOk"
    ok-text="保存"
    cancel-text="取消"
    :confirmLoading="loading"
  >
    <a-form-model ref="ruleForm" :model="upgradeForm" :rules="upgradeRules">
      <div class="flex">
        <a-form-model-item label="会员类型" prop="serviceType">
          <a-select
            placeholder="请选择"
            v-model="upgradeForm.serviceType"
            :disabled="config.serviceType === 1"
            :getPopupContainer="(triggerNode) => handleDislocation(triggerNode)"
            :default-active-first-option="false"
            :not-found-content="null"
            @select="handleSelect"
          >
            <a-select-option
              v-for="item in serviceOption"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="查看工厂家数"
          prop="buyNumber"
          v-if="config.serviceType !== 1 && upgradeForm.serviceType !== 1"
        >
          <a-input-number
            style="width: 100%"
            v-model="upgradeForm.buyNumber"
            :min="0"
            :max="999999"
            :precision="0"
          />
        </a-form-model-item>
        <a-form-model-item label="到期时间" prop="maturityTime" v-else>
          <a-date-picker
            style="width: 100%"
            v-model="upgradeForm.maturityTime"
            valueFormat="YYYY-MM-DD"
            :disabled-date="disabledDate"
          />
        </a-form-model-item>
      </div>
      <div class="serveice">
        <a-form-model-item
          label="购买凭证"
          prop="payVouchers"
          v-if="!(config.serviceType === 0 && upgradeForm.serviceType === 3)"
        >
          <UploadImage
            v-model="upgradeForm.payVouchers"
            :config="factoryConfig"
          ></UploadImage>
        </a-form-model-item>
      </div>
      <div class="serveice">
        <a-form-model-item label="备注" prop="remark">
          <a-textarea
            placeholder="请输入"
            v-model="upgradeForm.remark"
            :maxLength="200"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
    <a-table
      :columns="columns"
      :data-source="upgradeData"
      :pagination="false"
      :rowKey="(record) => record.id"
      v-if="config.serviceType !== 0"
    >
      <template slot="serviceType" slot-scope="text, record">
        <div>
          {{
            record.serviceType === 1
              ? "贸易端"
              : record.serviceType === 2
              ? "游客端"
              : "体验"
          }}
        </div>
      </template>
      <template slot="invalid" slot-scope="text, record">
        <div>
          {{ record.invalid ? "是" : "否" }}
        </div>
      </template>
      <template slot="payVouchers" slot-scope="text, record">
        <div>
          <UploadImage
            v-if="record.payVouchers"
            v-model="record.payVouchers[0]"
          ></UploadImage>
        </div> </template
      >
      <template slot="createTime" slot-scope="text, record">
        <div>
          {{ record.createTime }}
        </div>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: "服务",
    dataIndex: "serviceType",
    key: "serviceType",
    slots: { title: "serviceType" },
    scopedSlots: { customRender: "serviceType" },
  },
  {
    title: "服务情况",
    dataIndex: "serviceInfo",
    key: "serviceInfo",
    width: 190,
    slots: { title: "serviceInfo" },
    scopedSlots: { customRender: "serviceInfo" },
  },
  {
    title: "是否失效",
    dataIndex: "invalid",
    key: "invalid",
    ellipsis: true,
    slots: { title: "invalid" },
    scopedSlots: { customRender: "invalid" },
  },
  {
    title: "购买凭证",
    dataIndex: "payVouchers",
    key: "payVouchers",
    ellipsis: true,
    width: 200,
    slots: { title: "payVouchers" },
    scopedSlots: { customRender: "payVouchers" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
    ellipsis: true,
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "购买时间",
    dataIndex: "createTime",
    key: "createTime",
    ellipsis: true,
    width: 170,
    scopedSlots: { customRender: "createTime" },
  },
];
import { upgradeRules } from "@/utils/rules.js";
import UploadImage from "@/components/uploadImage";
import { upgradeApi } from "@/api/upgradeService";
import mixins from "@/mixins/mixins.js";
import moment from "moment";
export default {
  mixins: [mixins],
  props: {
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    upgradeModal: {
      type: Object,
      default() {
        return {
          visible: false,
        };
      },
    },
  },
  components: {
    UploadImage,
  },
  data() {
    return {
      columns,
      upgradeRules,
      loading: false,
      serviceOption: [
        { value: 1, name: "贸易端" },
        { value: 2, name: "游客端" },
        { value: 3, name: "体验" },
      ],
      serviceEnm: { 0: "无", 1: "贸易端", 2: "游客端", 3: "体验" },
      invalid: {},
      upgradeForm: {
        serviceType: null, //会员类型
        payVouchers: [], //付款凭证
        remark: null, //备注
        maturityTime: null,
      },
      upgradeData: [], //历史服务数据
      factoryConfig: {
        maxLength: 1, //1张
        maxSize: 10, //10M
      },
    };
  },
  methods: {
    handleSelect(val) {
      this.upgradeForm = {
        serviceType: val, //会员类型
        payVouchers: [], //付款凭证
        remark: null, //备注
        maturityTime: null,
        buyNumber: null,
      };
      this.$refs.ruleForm.resetFields();
    },
    disabledDate(current) {
      // 只能选择有效期之后的时间
      if (this.config.maturityTime) {
        return (
          current && current < moment(this.config.maturityTime).endOf("day")
        );
      } else {
        // 只能选择今天之后的时间
        return current && current < moment().endOf("day");
      }
    },
    async handleOk() {
      this.$refs.ruleForm.validate(async (res) => {
        if (res) {
          // 保存接口
          const serviceUpdateDTO = {
            ...this.upgradeForm,
            payVouchers:
              this.upgradeForm.serviceType === 3
                ? null
                : this.upgradeForm.payVouchers,
            tradeId: this.config.tradeId,
          };
          this.loading = true;
          const res = await upgradeApi.serviceUpdate(serviceUpdateDTO);
          if (res.success) {
            this.loading = false;
            this.handleCancel();
            this.$parent.getSourceData("退回");
            this.$message.success("保存成功");
          } else {
            this.loading = false;
            this.$message.error(res.errorMsg);
            throw new Error(res.errorMsg);
          }
        }
      });
    },
    handleCancel() {
      this.upgradeModal.visible = false;
    },
    async getServiceDetail() {
      const res = await upgradeApi.getPayHistory({
        tradeId: this.config.tradeId,
      });
      this.upgradeData = res.data;
      if (!this.config.serviceType || this.config.serviceType === 3) {
        this.upgradeForm.serviceType = null;
      } else {
        this.upgradeForm.serviceType = this.config.serviceType;
      }
      if (this.config.serviceType === 1) {
        return (this.serviceOption = [
          { value: 1, name: "贸易端" },
          { value: 2, name: "游客端" },
          { value: 3, name: "体验" },
        ]);
      }
      if (this.config.serviceType === 2 || this.config.serviceType === 3) {
        return (this.serviceOption = [
          { value: 1, name: "贸易端" },
          { value: 2, name: "游客端" },
        ]);
      }
    },
  },
  mounted() {
    this.getServiceDetail();
  },
};
</script>
<style lang="scss" scoped>
.factory-check {
  width: 300px;
  margin-right: 27px;
}
/deep/.ant-form-item-label {
  text-align: left;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/.ant-form-item {
    width: 47%;
  }
}
.opertation {
  margin-right: 10px;
  color: rgb(255, 70, 0);
}
</style>
