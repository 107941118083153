<template>
  <div class="form-input-container">
    <div class="title" :class="{ star: config.starVisible }">
      {{ config.title }}:
    </div>
    <a-input
      class="dis-input"
      disabled
      v-model="config.content"
      :style="{ color: `${getColor(config.color)}` }"
    />
  </div>
</template>

<script>
import { getColor } from "@/utils/color";
export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          title: "",
          content: "",
          starVisible: false,
          color: "",
        };
      },
    },
  },
  methods: {
    getColor,
  },
};
</script>

<style lang="scss" scoped>
.form-input-container {
  display: flex;
  .title {
    width: 17.7rem;
    margin-right: 1rem;
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .dis-input {
    color: rgb(99, 98, 98);
  }
}
.ant-input {
  width: 28.5rem;
}
</style>
