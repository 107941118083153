import axios from "@/config/http.js";

export const API = {
  // 商户查询首页数据
  async pageIndex(params) {
    return await axios.get("/inquiry/trader/page", { params });
  },
  // 查看商户详情
  async detailData(id) {
    return await axios.get(`/inquiry/trader/detail?tradeId=${id}`);
  },
  // 审核商户
  async examineMerchant(params) {
    return await axios.patch("/inquiry/trader/check", params);
  },
  // 产品主要出口销售地下拉
  async saleArea() {
    return await axios.get("/inquiry/trader/dropdown/salesArea");
  },
};
